import React, { useState, useEffect } from 'react';
import { Printer, Save, Trash2, HelpCircle } from 'lucide-react'; // Add HelpCircle icon
import { jsPDF } from "jspdf";

const OptionsDrawer = ({
  onGenerateFollowUp, 
  onCreateWorksheetAndAnswerKey, 
  isLessonPlanGenerated, 
  isGenerating,
  lessonPlan,
  subject,
  gradeLevel,
  duration,
  followUp,
  worksheet,
  answerKey,
  onLoadFromHistory,
  onDeleteFromHistory

}) => {
  const [historyEntries, setHistoryEntries] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const savedHistory = JSON.parse(localStorage.getItem('lessonPlanHistory')) || [];
    setHistoryEntries(savedHistory);
  }, []);

  const buttonClass = `
    inline-flex items-center justify-center px-4 py-2
    bg-white hover:bg-gray-50 text-gray-700 text-sm
    border border-gray-300 rounded-md shadow-sm
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
    transition-colors duration-200 ease-in-out
    disabled:opacity-50 disabled:cursor-not-allowed
    w-full md:w-auto
  `;

  const printContent = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>Lesson Plan</title></head><body>');
    if (lessonPlan) {
      printWindow.document.write('<h2>Lesson Plan</h2>');
      printWindow.document.write(`<p>${lessonPlan.replace(/\n/g, '<br>')}</p>`);
    }
    if (followUp) {
      printWindow.document.write('<h2>Follow-Up</h2>');
      printWindow.document.write(`<p>${followUp.replace(/\n/g, '<br>')}</p>`);
    }
    if (worksheet) {
      printWindow.document.write('<h2>Worksheet</h2>');
      printWindow.document.write(`<p>${worksheet.replace(/\n/g, '<br>')}</p>`);
    }
    if (answerKey) {
      printWindow.document.write('<h2>Answer Key</h2>');
      printWindow.document.write(`<p>${answerKey.replace(/\n/g, '<br>')}</p>`);
    }
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  const saveAsPDF = () => {
    const doc = new jsPDF();
    let yOffset = 10;

    const addSection = (title, content) => {
      if (content) {
        doc.setFontSize(16);
        doc.text(title, 10, yOffset);
        yOffset += 10;
        doc.setFontSize(12);
        const splitContent = doc.splitTextToSize(content, 190);
        splitContent.forEach(line => {
          if (yOffset > 280) {
            doc.addPage();
            yOffset = 10;
          }
          doc.text(line, 10, yOffset);
          yOffset += 7;
        });
        yOffset += 10;
      }
    };

    addSection('Lesson Plan', lessonPlan);
    addSection('Follow-Up', followUp);
    addSection('Worksheet', worksheet);
    addSection('Answer Key', answerKey);

    doc.save(`Lesson_Plan_${subject}_${gradeLevel}.pdf`);
  };

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <button
          className={buttonClass}
          onClick={onGenerateFollowUp}
          disabled={!isLessonPlanGenerated || isGenerating}
        >
          <span className="font-medium">Generate Follow-Up</span>
        </button>
        <button
          className={buttonClass}
          onClick={onCreateWorksheetAndAnswerKey}
          disabled={!isLessonPlanGenerated || isGenerating}
        >
          <span className="font-medium">Create Worksheet & Answer Key</span>
        </button>
        <button
          className={buttonClass}
          onClick={printContent}
          disabled={!isLessonPlanGenerated}
        >
          <Printer className="mr-2" size={18} />
          <span className="font-medium">Print</span>
        </button>
        <button
          className={buttonClass}
          onClick={saveAsPDF}
          disabled={!isLessonPlanGenerated}
        >
          <Save className="mr-2" size={18} />
          <span className="font-medium">Save as PDF</span>
        </button>
      </div>
      <div className="mt-4">
        <div className="flex items-center mb-2 relative">
          <h3 className="text-lg font-semibold">History</h3>
          <div className="ml-2 relative">
            <HelpCircle
              size={18}
              className="text-gray-400 cursor-pointer hover:text-gray-600 transition-colors duration-200"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            />
            {showTooltip && (
              <div className="absolute left-full ml-2 top-1/2 transform -translate-y-1/2 w-64 p-3 bg-white text-gray-800 text-sm rounded-lg shadow-lg border border-gray-200 z-10">
                <p className="mb-2">
                </p>
                <p className="mb-2">
                  <span className="font-semibold text-yellow-600">Note:</span> If you clear your browser cache, your history will be deleted - so be sure to save your favorite lesson plans as PDFs!
                </p>
                <p>
                </p>
                <div className="absolute left-0 top-1/2 transform -translate-x-full -translate-y-1/2 w-0 h-0 border-t-8 border-b-8 border-r-8 border-transparent border-r-white"></div>
              </div>
            )}
          </div>
        </div>
        {historyEntries.length > 0 ? (
          <ul className="space-y-2">
            {historyEntries.map((entry) => (
              <li key={entry.id} className="bg-gray-50 p-3 rounded">
                <div className="flex flex-col mb-2">
                  <span className="font-medium text-[#186eef]">{entry.subject}</span>
                  <span className="text-sm text-gray-600">{entry.gradeLevel} | {entry.duration}</span>
                  <span className="text-xs text-gray-500">
                    {new Date(entry.date).toLocaleString(undefined, {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </span>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => onLoadFromHistory(entry)}
                    className="flex-1 px-3 py-1 bg-gray-500 text-white text-sm rounded hover:bg-gray-600 transition-colors duration-200"
                  >
                    Load
                  </button>
                  <button
                    onClick={() => onDeleteFromHistory(entry.id)}
                    className="px-3 py-1 bg-red-600 text-white text-sm rounded hover:bg-red-700 transition-colors duration-200"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">No history available</p>
        )}
      </div>
    </div>
  );
};

export default OptionsDrawer;