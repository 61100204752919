// App.js
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { db, analytics } from './firebase';
import { collection, addDoc, updateDoc, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { trackEvent } from './utils/analytics';
import './index.css';
import AppLayout from './components/AppLayout';
import LessonPlanForm from './components/LessonPlanForm';
import LessonPlanDisplay from './components/LessonPlanDisplay';
import OptionsDrawer from './components/OptionsDrawer';
import LoadingPopup from './components/LoadingPopup';
import UsageGate from './components/UsageGate';
import FeedbackPopup from './components/FeedbackPopup';
import app from './firebase';

function App() {
  const [lessonPlan, setLessonPlan] = useState('');
  const [subject, setSubject] = useState('');
  const [gradeLevel, setGradeLevel] = useState('');
  const [duration, setDuration] = useState('');
  const [followUp, setFollowUp] = useState('');
  const [worksheet, setWorksheet] = useState('');
  const [answerKey, setAnswerKey] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLessonPlanGenerated, setIsLessonPlanGenerated] = useState(false);
  const [isFollowUpGenerated, setIsFollowUpGenerated] = useState(false);
  const [isWorksheetGenerated, setIsWorksheetGenerated] = useState(false);
  const [activeTab, setActiveTab] = useState('lessonPlan');
  const [isStreaming, setIsStreaming] = useState(false);
  const [showUsageGate, setShowUsageGate] = useState(false);
  const [state, setState] = useState('');
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);

  // Add this new effect at the beginning of your component
  useEffect(() => {
    // Function to get URL parameters
    const getUrlParameter = (name) => {
      const regex = new RegExp(`[?&]${name}=([^&#]*)`);
      const results = regex.exec(window.location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };
  
    // Check for the specific UTM parameter
    const utmSource = getUrlParameter('utm_source');
    if (utmSource === 'hs_email') {  // Replace with your specific UTM value
      localStorage.setItem('bypassUsageGate', 'true');
    }
  }, []);

  // Sending app size to ensure hubspot embed functions correctly
  const appRef = useRef(null);

  const sendHeight = useCallback(() => {
    if (appRef.current) {
      const height = appRef.current.scrollHeight;
      window.parent.postMessage({ type: 'setHeight', height: height }, '*');
    }
  }, []);

  useEffect(() => {
    sendHeight();
    window.addEventListener('resize', sendHeight);

    const observer = new MutationObserver(() => {
      setTimeout(sendHeight, 0);
    });

    observer.observe(appRef.current, {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: true
    });

    const intervalId = setInterval(sendHeight, 1000);

    return () => {
      window.removeEventListener('resize', sendHeight);
      observer.disconnect();
      clearInterval(intervalId);
    };
  }, [sendHeight]);

  useEffect(() => {
    sendHeight();
  }, [lessonPlan, followUp, worksheet, answerKey, sendHeight]);

  // New state to track if content has changed since last save
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // New state to store the current entry ID
  const [currentEntryId, setCurrentEntryId] = useState(null);

  const lessonPlanRef = useRef(null);
  const followUpRef = useRef(null);
  const worksheetRef = useRef(null);
  const answerKeyRef = useRef(null);

  const saveToHistory = useCallback(() => {
    let history = JSON.parse(localStorage.getItem('lessonPlanHistory')) || [];
    
    const updatedEntry = {
      id: currentEntryId,
      date: new Date().toLocaleString(),
      subject,
      gradeLevel,
      duration,
      lessonPlan,
      followUp,
      worksheet,
      answerKey,
      state
    };

    // Check if an entry with the current ID already exists
    const existingEntryIndex = history.findIndex(entry => entry.id === currentEntryId);

    if (existingEntryIndex !== -1) {
      // Update existing entry
      history[existingEntryIndex] = updatedEntry;
    } else {
      // Add new entry
      history = [updatedEntry, ...history];
    }

    // Keep only the last 10 entries
    history = history.slice(0, 10);
    
    localStorage.setItem('lessonPlanHistory', JSON.stringify(history));
  }, [currentEntryId, subject, gradeLevel, duration, lessonPlan, followUp, worksheet, answerKey, state]);

  const saveToFirestore = useCallback(async () => {
    if (!isLessonPlanGenerated) return;

    const lessonPlanData = {
      date: new Date().toISOString(),
      subject: subject || '',
      gradeLevel: gradeLevel || '',
      duration: duration || '',
      lessonPlan: lessonPlan || '',
      followUp: followUp || '',
      worksheet: worksheet || '',
      answerKey: answerKey || '',
      state: state || ''
    };

    try {
      console.log('Attempting to save to Firestore:', lessonPlanData);
      console.log('Current Entry ID:', currentEntryId);

      let docRef;
      if (currentEntryId && typeof currentEntryId === 'string') {
        console.log('Updating existing document with ID:', currentEntryId);
        docRef = doc(db, 'lessonPlans', currentEntryId);
        await updateDoc(docRef, lessonPlanData);
      } else {
        console.log('Adding new document');
        docRef = await addDoc(collection(db, 'lessonPlans'), lessonPlanData);
        setCurrentEntryId(docRef.id);
      }
      console.log('Document saved to Firestore with ID:', docRef.id);
    } catch (error) {
      console.error('Error saving document to Firestore:', error);
      console.error('Error details:', error.stack);
      console.error('Current Entry ID:', currentEntryId);
      console.error('Lesson Plan Data:', lessonPlanData);
      
      if (error.code === 'not-found') {
        console.error('Attempted to update non-existent document. Creating new document instead.');
        try {
          const newDocRef = await addDoc(collection(db, 'lessonPlans'), lessonPlanData);
          setCurrentEntryId(newDocRef.id);
          console.log('New document created with ID:', newDocRef.id);
        } catch (newError) {
          console.error('Error creating new document:', newError);
        }
      } else {
        console.error('Unknown Firebase error:', error.code);
      }
    }
  }, [currentEntryId, subject, gradeLevel, duration, lessonPlan, followUp, worksheet, answerKey, state, isLessonPlanGenerated]);

  // Effect to save changes automatically
  useEffect(() => {
    if (hasUnsavedChanges && isLessonPlanGenerated) {
      saveToHistory();
      saveToFirestore();
      setHasUnsavedChanges(false);
    }
  }, [hasUnsavedChanges, isLessonPlanGenerated, saveToHistory, saveToFirestore]);
  
  // Effect to save changes automatically
  useEffect(() => {
    if (hasUnsavedChanges && isLessonPlanGenerated) {
      saveToHistory();
      saveToFirestore();
      setHasUnsavedChanges(false);
    }
  }, [hasUnsavedChanges, isLessonPlanGenerated, saveToHistory, saveToFirestore]);

  const generateContent = async (action, formData = {}) => {
    const contentSetter = {
      generateLessonPlan: setLessonPlan,
      generateFollowUp: setFollowUp,
      createWorksheet: setWorksheet,
      createAnswerKey: setAnswerKey,
    }[action];
  
    contentSetter('');
    let accumulatedContent = '';
    let buffer = ''; // Buffer to accumulate incomplete JSON chunks
  
    try {
      setIsStreaming(true); // Start streaming
      const body = {
        action,
        ...formData,
        initialLessonPlan: lessonPlan,
        worksheet: formData.additionalDetails,
        state: formData.state
      };
  
      console.log(`Sending request to API for ${action}:`, body);
  
      const response = await fetch('https://swinglessonplangenerator.paul-maxwell-campbell.workers.dev', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
  
      while (true) {
        const { done, value } = await reader.read();
  
        if (done) {
          break;
        }
  
        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk; // Accumulate chunk into buffer
  
        let boundary = buffer.indexOf('\n');
        while (boundary !== -1) {
          const line = buffer.slice(0, boundary).trim();
          buffer = buffer.slice(boundary + 1); // Update buffer to remove processed line
          boundary = buffer.indexOf('\n');
  
          if (line.startsWith('data: ')) {
            const data = line.slice(5).trim();
            if (data === '[DONE]') break;
  
            try {
              const jsonData = JSON.parse(data);
              if (jsonData.choices && jsonData.choices[0] && jsonData.choices[0].delta && jsonData.choices[0].delta.content) {
                const content = jsonData.choices[0].delta.content;
                accumulatedContent += content;
                contentSetter(accumulatedContent);
                console.log('Added content:', content);
              }
            } catch (error) {
              console.error('Error parsing JSON:', error);
              console.error('Problematic data:', data);
              // The line might be incomplete; add it back to the buffer for the next iteration
              buffer = line + '\n' + buffer;
              break; // Exit the loop to wait for more data
            }
          }
        }
      }
  
      console.log(`Completed ${action}. Total content length:`, accumulatedContent.length);
      console.log('Final accumulated content:', accumulatedContent);
  
      return accumulatedContent;
    } catch (error) {
      console.error(`Error generating ${action}:`, error);
      contentSetter(`An error occurred while generating the ${action}. Please try again.`);
      return '';
    } finally {
      setIsStreaming(false); // End streaming
    }
  };
  

  const scrollToContent = useCallback((contentRef, tabName) => {
    setActiveTab(tabName); // Switch to the correct tab first
    
    const attemptScroll = (attempts = 0) => {
      if (attempts > 5) {
        console.error(`Failed to scroll to ${tabName} after 5 attempts`);
        return;
      }
  
      setTimeout(() => {
        if (contentRef.current && typeof contentRef.current.scrollIntoView === 'function') {
          contentRef.current.scrollIntoView({ behavior: 'smooth' });
        } else {
          console.log(`Attempt ${attempts + 1}: Unable to scroll to ${tabName}, ref not ready. Retrying...`);
          attemptScroll(attempts + 1);
        }
      }, 100 * (attempts + 1)); // Increase delay with each attempt
    };
  
    attemptScroll();
  }, []);
  
  // Functions for UsageGate
  const handleUsageGateClose = () => {
    setShowUsageGate(false);
  };

  const handleUsageGateSubmit = () => {
    setShowUsageGate(false);
    // Reset usage count and store user info
    localStorage.setItem('usageCount', '0');
    localStorage.setItem('userInfo', JSON.stringify({ submitted: true }));
    logAnalyticsEvent('usage_gate_submit');
  };
  
  const handleGenerateLessonPlan = async (formData) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const usageCount = parseInt(localStorage.getItem('usageCount')) || 0;
    const bypassUsageGate = localStorage.getItem('bypassUsageGate') === 'true';
    const analyticsInstance = await analytics;
      if (analyticsInstance) {
        trackEvent(analyticsInstance, 'generate_lesson_plan', {
          subject: formData.subject,
          gradeLevel: formData.gradeLevel,
          duration: formData.duration,
          state: formData.state
        });
}
    // Increment usage count regardless of user info
    localStorage.setItem('usageCount', (usageCount + 1).toString());

    // Check if it's the 20th submission
    if (usageCount === 10) {
      setShowFeedbackPopup(true);
    }

    setIsGenerating(true);
    setLessonPlan(''); // Clear existing lesson plan
    setActiveTab('lessonPlan'); // Ensure we're on the lesson plan tab
    
    // Update subject, gradeLevel, and duration
    setSubject(formData.subject);
    setGradeLevel(formData.gradeLevel);
    setDuration(formData.duration);
    setState(formData.state);
    // Clear additional sections
    setFollowUp('');
    setWorksheet('');
    setAnswerKey('');
    setIsFollowUpGenerated(false);
    setIsWorksheetGenerated(false);
    
    try {
      // Generate lesson plan regardless of user info
      const generatedPlan = await generateContent('generateLessonPlan', formData);
      if (generatedPlan) {
        setLessonPlan(generatedPlan);
        setIsLessonPlanGenerated(true);
        setHasUnsavedChanges(true);
        // Generate a new entry ID for this lesson plan
        setCurrentEntryId(null); // Reset currentEntryId for new lesson plans
        console.log('Current Entry ID reset for new lesson plan');
        scrollToContent(lessonPlanRef, 'lessonPlan');
      } else {
        setIsLessonPlanGenerated(false);
      }

      // Show UsageGate if user hasn't submitted info, usage count is 0 or more, and bypass is not set
      if (!userInfo && usageCount >= 0 && !bypassUsageGate) {
        setShowUsageGate(true);
      }

    } catch (error) {
      console.error('Error generating lesson plan:', error);
      setIsLessonPlanGenerated(false);
    } finally {
      setIsGenerating(false);
    }
  };
  
  const handleGenerateFollowUp = async () => {
    setIsGenerating(true);
    logAnalyticsEvent('generate_follow_up');
    try {
      const generatedFollowUp = await generateContent('generateFollowUp', {
        gradeLevel: '',
        subject: '',
        duration: '',
        additionalDetails: `Generate a follow-up activity based on this lesson plan: ${lessonPlan}`
      });
      
      if (generatedFollowUp) {
        setFollowUp(generatedFollowUp);
        setIsFollowUpGenerated(true);
        setHasUnsavedChanges(true);
        setIsDrawerOpen(false); // Close the options drawer
        scrollToContent(followUpRef, 'followUp');
      } else {
        setIsFollowUpGenerated(false);
        console.error('Failed to generate follow-up content');
      }
    } catch (error) {
      console.error('Error in handleGenerateFollowUp:', error);
      setIsFollowUpGenerated(false);
    } finally {
      setIsGenerating(false);
    }
  };
  
  const handleCreateWorksheetAndAnswerKey = async () => {
    setIsGenerating(true);
    logAnalyticsEvent('create_worksheet_and_answer_key', {
      subject: subject,
      gradeLevel: gradeLevel,
      duration: duration
    });
    let worksheetGenerated = false;
    let answerKeyGenerated = false;
    let generatedWorksheetContent = '';
  
    try {
      // Generate worksheet
      generatedWorksheetContent = await generateContent('createWorksheet', {
        gradeLevel: '',
        subject: '',
        duration: '',
        additionalDetails: `Create a worksheet based on this lesson plan: ${lessonPlan}. The worksheet should only require a pen and paper, should not include any links or images, should have space for students to write in answers, and should take roughly 20 minutes to complete.`
      });
  
      if (generatedWorksheetContent) {
        setWorksheet(generatedWorksheetContent);
        worksheetGenerated = true;
        console.log('Worksheet generated successfully. Length:', generatedWorksheetContent.length);
  
        // Only generate answer key if worksheet was successfully generated
        if (generatedWorksheetContent.trim().length > 0) {
          const generatedAnswerKey = await generateContent('createAnswerKey', {
            gradeLevel: '',
            subject: '',
            duration: '',
            additionalDetails: `Create an answer key for this worksheet: ${generatedWorksheetContent}. Provide the correct answers for each section.`
          });
  
          if (generatedAnswerKey) {
            setAnswerKey(generatedAnswerKey);
            answerKeyGenerated = true;
            console.log('Answer key generated successfully. Length:', generatedAnswerKey.length);
          } else {
            console.error('Failed to generate answer key: Empty response');
          }
        } else {
          console.error('Generated worksheet is empty, cannot create answer key');
        }
      } else {
        console.error('Failed to generate worksheet: Empty response');
      }
  
    } catch (error) {
      console.error('Error in handleCreateWorksheetAndAnswerKey:', error);
    } finally {
      setIsGenerating(false);
      setIsWorksheetGenerated(worksheetGenerated);
      setHasUnsavedChanges(worksheetGenerated || answerKeyGenerated);
      setIsDrawerOpen(false); // Close the options drawer
  
      if (worksheetGenerated) {
        scrollToContent(worksheetRef, 'worksheet');
      }
    }
  
    // Log the outcome
    console.log(`Worksheet generation: ${worksheetGenerated ? 'Success' : 'Failed'}`);
    console.log(`Answer Key generation: ${answerKeyGenerated ? 'Success' : 'Failed'}`);
  };

  const handleLoadFromHistory = async (entry) => {
    logAnalyticsEvent('load_from_history', { entryId: entry.id });
    // Load from local storage
    const localHistory = JSON.parse(localStorage.getItem('lessonPlanHistory')) || [];
    const localEntry = localHistory.find(item => item.id === entry.id);

    if (localEntry) {
      setSubject(localEntry.subject || '');
      setGradeLevel(localEntry.gradeLevel || '');
      setDuration(localEntry.duration || '');
      setLessonPlan(localEntry.lessonPlan || '');
      setFollowUp(localEntry.followUp || '');
      setWorksheet(localEntry.worksheet || '');
      setAnswerKey(localEntry.answerKey || '');
      setState(localEntry.state || '');
    }

    // Load from Firestore
    try {
      const docRef = doc(db, 'lessonPlans', entry.id);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        setSubject(data.subject || '');
        setGradeLevel(data.gradeLevel || '');
        setDuration(data.duration || '');
        setLessonPlan(data.lessonPlan || '');
        setFollowUp(data.followUp || '');
        setWorksheet(data.worksheet || '');
        setAnswerKey(data.answerKey || '');
        setState(data.state || '');
      }
    } catch (error) {
      console.error('Error loading from Firestore:', error);
    }

    setIsLessonPlanGenerated(true);
    setIsFollowUpGenerated(!!entry.followUp);
    setIsWorksheetGenerated(!!entry.worksheet);
    setCurrentEntryId(entry.id);

    // Close the options drawer
    setIsDrawerOpen(false);
    
    // Use setTimeout to ensure state updates have completed
    setTimeout(() => {
      // Scroll to lesson plan content and switch to lesson plan tab
      scrollToContent(lessonPlanRef, 'lessonPlan');
    }, 0);
  };

  // Move logAnalyticsEvent definition here, before it's used
  const logAnalyticsEvent = useCallback((eventName, eventParams = {}) => {
    // Use the new trackEvent function
    analytics.then(analyticsInstance => {
      trackEvent(analyticsInstance, eventName, eventParams);
    });

    // Add postMessage to parent window with console.log
    console.log('Sending analytics event to parent:', eventName, eventParams);
    window.parent.postMessage({
      type: 'ANALYTICS_EVENT',
      eventName,
      eventParams
    }, '*');
  }, []);

  // Now define handleDeleteFromHistory
  const handleDeleteFromHistory = useCallback(async (id) => {
    logAnalyticsEvent('delete_from_history', { entryId: id });
    // Delete from local storage
    let history = JSON.parse(localStorage.getItem('lessonPlanHistory')) || [];
    history = history.filter(entry => entry.id !== id);
    localStorage.setItem('lessonPlanHistory', JSON.stringify(history));

    // Delete from Firestore
    try {
      await deleteDoc(doc(db, 'lessonPlans', id));
      console.log('Document deleted from Firestore');
    } catch (error) {
      console.error('Error deleting document from Firestore:', error);
    }

    // Refresh the OptionsDrawer component
    setIsDrawerOpen(false);
    setTimeout(() => setIsDrawerOpen(true), 0);
  }, [logAnalyticsEvent]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === 'UTM_SOURCE' && event.data.value === 'hs_email') {
        localStorage.setItem('bypassUsageGate', 'true');
        console.log('bypassUsageGate set to true via postMessage'); // Debug log
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    console.log('Firebase app initialized:', app);
  }, []);

  // Add event for options drawer
  useEffect(() => {
    if (isDrawerOpen) {
      logAnalyticsEvent('open_options_drawer');
    }
  }, [isDrawerOpen, logAnalyticsEvent]);

  // Update setActiveTab to log tab changes
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    logAnalyticsEvent('tab_change', { tabName });
  };

  // Add event for form submission errors
  const handleFormSubmissionError = (error) => {
    logAnalyticsEvent('form_submission_error', { errorMessage: error.message });
  };

  const handleCloseFeedbackPopup = () => {
    setShowFeedbackPopup(false);
  };

  return (
    <AppLayout>
      {showUsageGate && (
        <UsageGate onClose={handleUsageGateClose} onSubmit={handleUsageGateSubmit} />
      )}
      {isStreaming && <LoadingPopup />}
      <div ref={appRef} className="space-y-8">
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-6">Create a Lesson Plan</h2>
          <LessonPlanForm onSubmit={handleGenerateLessonPlan} isGenerating={isGenerating} onError={handleFormSubmissionError} />
          <button
            className="mt-4 w-full bg-gray-100 hover:bg-gray-200 text-gray-700 text-sm font-medium py-2 px-4 rounded transition duration-300 ease-in-out flex items-center justify-center border border-gray-300"
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          >
            <span>{isDrawerOpen ? 'Hide Options' : 'More Options'}</span>
            <svg
              className={`ml-2 h-4 w-4 transform transition-transform duration-200 ${
                isDrawerOpen ? 'rotate-180' : ''
              }`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {isDrawerOpen && (
            <div className="mt-4">
              <OptionsDrawer 
                onGenerateFollowUp={handleGenerateFollowUp}
                onCreateWorksheetAndAnswerKey={handleCreateWorksheetAndAnswerKey}
                isLessonPlanGenerated={isLessonPlanGenerated}
                isGenerating={isGenerating}
                lessonPlan={lessonPlan}
                subject={subject}
                gradeLevel={gradeLevel}
                duration={duration}
                followUp={followUp}
                worksheet={worksheet}
                answerKey={answerKey}
                onLoadFromHistory={handleLoadFromHistory}
                onDeleteFromHistory={handleDeleteFromHistory}
              />
            </div>
          )}
        </div>

        {lessonPlan && (
          <LessonPlanDisplay 
            lessonPlanRef={lessonPlanRef}
            followUpRef={followUpRef}
            worksheetRef={worksheetRef}
            answerKeyRef={answerKeyRef}
            activeTab={activeTab}
            setActiveTab={handleTabChange}
            lessonPlan={lessonPlan}
            subject={subject}
            gradeLevel={gradeLevel}
            duration={duration}
            followUp={followUp}
            worksheet={worksheet}
            answerKey={answerKey}
            isFollowUpGenerated={isFollowUpGenerated}
            isWorksheetGenerated={isWorksheetGenerated}
            onCreateWorksheet={handleCreateWorksheetAndAnswerKey}
            isGenerating={isGenerating}
            state={state}
          />
        )}
      </div>
      {showFeedbackPopup && <FeedbackPopup onClose={handleCloseFeedbackPopup} />}
    </AppLayout>
  );
}

export default App;