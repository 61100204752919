import React, { useState, useEffect, useRef } from 'react';
import { Wand2 } from 'lucide-react';

const UsageGate = ({ onSubmit, isOverlay = false }) => {
  const [isVisible, setIsVisible] = useState(false);
  const formContainerRef = useRef(null);

  useEffect(() => {
    const savedCount = parseInt(localStorage.getItem('usageCount')) || 0;
    if (savedCount >= 1) {
      setIsVisible(true);
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      const currentFormContainer = formContainerRef.current;
      
      if (currentFormContainer) {
        const script = document.createElement('script');
        script.src = "//js.hsforms.net/forms/v2.js";
        script.async = true;
        script.onload = () => {
          if (window.hbspt) {
            window.hbspt.forms.create({
              region: "na1",
              portalId: "4523782",
              formId: "098db411-199b-4419-a975-36ffe2c6a798",
              target: "#hubspotForm",
              cssClass: 'custom-form',
              onFormSubmitted: function($form) {
                onSubmit();
                setIsVisible(false);
              }
            });
          }
        };
        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
          if (currentFormContainer) {
            currentFormContainer.innerHTML = '';
          }
        };
      }
    }
  }, [isVisible, onSubmit]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={`fixed inset-0 bg-black ${isOverlay ? 'bg-opacity-40' : 'bg-opacity-30'} z-[9999] flex items-center justify-center p-2 sm:p-4 overflow-y-auto`}>
      <div className="bg-white rounded-xl shadow-2xl max-w-[95%] w-full sm:max-w-[90%] sm:w-[400px] p-4 sm:p-6 relative">
        <div className="flex items-center justify-center mb-2 sm:mb-4">
          <Wand2 className="text-purple-500 mr-2" size={20} />
          <h2 className="text-xl sm:text-2xl font-bold text-center text-gray-800">
            Unlock Access!
          </h2>
        </div>
        <p className="text-center text-gray-600 mb-4 sm:mb-6 text-xs sm:text-sm">
          Sign up now to continue using our magical lesson plan generator for free. Join thousands of educators enhancing their teaching experience!
        </p>
        <div id="hubspotForm" ref={formContainerRef}></div>
        <p className="text-center text-xs sm:text-sm text-gray-500 mt-2 sm:mt-4">
          By signing up, you agree to our{' '}
          <a 
            href="https://swingeducation.com/terms/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-purple-600 hover:text-purple-800 underline"
          >
            Terms of Service
          </a>
          .
        </p>
      </div>
      <style jsx global>{`
        .custom-form .hs-form-field {
          margin-bottom: 0.5rem;
        }
        .custom-form .hs-form-field label {
          font-size: 0.75rem;
        }
        .custom-form .hs-input {
          font-size: 0.75rem;
          padding: 0.25rem 0.375rem;
        }
        .custom-form .hs-button {
          padding: 0.375rem 0.5rem;
          font-size: 0.75rem;
        }
        @media (min-width: 640px) {
          .custom-form .hs-form-field {
            margin-bottom: 0.75rem;
          }
          .custom-form .hs-form-field label {
            font-size: 0.875rem;
          }
          .custom-form .hs-input {
            font-size: 0.875rem;
            padding: 0.375rem 0.5rem;
          }
          .custom-form .hs-button {
            padding: 0.5rem 0.75rem;
            font-size: 0.875rem;
          }
        }
        /* ... other existing styles ... */
      `}</style>
    </div>
  );
};

export default UsageGate;