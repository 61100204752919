import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';

const gradeOptions = [
  'Kindergarten', '1st Grade', '2nd Grade', '3rd Grade', '4th Grade', '5th Grade',
  '6th Grade', '7th Grade', '8th Grade', '9th Grade', '10th Grade', '11th Grade', '12th Grade'
];

const durationOptions = ['15 minutes', '30 minutes', '60 minutes', '80 minutes'];

const stateOptions = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
  'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
  'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
  'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
  'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'Washington D.C.', 'West Virginia', 'Wisconsin', 'Wyoming'
];

function LessonPlanForm({ onSubmit, isGenerating }) {
  const [formData, setFormData] = useState({
    gradeLevel: '',
    subject: '',
    duration: '',
    state: '',
    additionalDetails: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // Clear error for the field being changed
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.gradeLevel) newErrors.gradeLevel = 'Please select a grade level';
    if (!formData.subject) newErrors.subject = 'Please enter a subject';
    if (!formData.duration) newErrors.duration = 'Please select a duration';
    if (!formData.state) newErrors.state = 'Please select a state';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      onSubmit(formData);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <div>
          <label htmlFor="gradeLevel" className="block text-sm font-medium text-gray-700">Grade Level *</label>
          <div className="mt-1 relative">
            <select
              id="gradeLevel"
              name="gradeLevel"
              value={formData.gradeLevel}
              onChange={handleChange}
              className={`block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md appearance-none ${errors.gradeLevel ? 'border-red-500' : ''}`}
              required
            >
              <option value="">Select a grade level</option>
              {gradeOptions.map((grade) => (
                <option key={grade} value={grade}>{grade}</option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <ChevronDown className="h-5 w-5 text-gray-400" />
            </div>
          </div>
          {errors.gradeLevel && <p className="mt-1 text-sm text-red-600">{errors.gradeLevel}</p>}
        </div>
        <div>
          <label htmlFor="subject" className="block text-sm font-medium">Subject *</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${errors.subject ? 'border-red-500' : ''}`}
            placeholder="e.g., Mathematics, Science, History"
            required
          />
          {errors.subject && <p className="mt-1 text-sm text-red-600">{errors.subject}</p>}
        </div>
        <div>
          <label htmlFor="state" className="block text-sm font-medium text-gray-700">State/Region *</label>
          <div className="mt-1 relative">
            <select
              id="state"
              name="state"
              value={formData.state}
              onChange={handleChange}
              className={`block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md appearance-none ${errors.state ? 'border-red-500' : ''}`}
              required
            >
              <option value="">Select a state/region</option>
              {stateOptions.map((state) => (
                <option key={state} value={state}>{state}</option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <ChevronDown className="h-5 w-5 text-gray-400" />
            </div>
          </div>
          {errors.state && <p className="mt-1 text-sm text-red-600">{errors.state}</p>}
        </div>
      </div>
      <div>
        <label htmlFor="duration" className="block text-sm font-medium text-gray-700">Duration *</label>
        <div className="mt-1 relative">
          <select
            id="duration"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            className={`block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md appearance-none ${errors.duration ? 'border-red-500' : ''}`}
            required
          >
            <option value="">Select a duration</option>
            {durationOptions.map((duration) => (
              <option key={duration} value={duration}>{duration}</option>
            ))}
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
            <ChevronDown className="h-5 w-5 text-gray-400" />
          </div>
        </div>
        {errors.duration && <p className="mt-1 text-sm text-red-600">{errors.duration}</p>}
      </div>
      <div>
        <label htmlFor="additionalDetails" className="block text-sm font-medium text-gray-700">Additional Details (optional)</label>
        <textarea
          id="additionalDetails"
          name="additionalDetails"
          value={formData.additionalDetails}
          onChange={handleChange}
          rows="4"
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          placeholder="Any specific topics, learning objectives, or special requirements..."
        ></textarea>
      </div>
      <div>
        <button
          type="submit"
          disabled={isGenerating}
          className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-900 bg-[#ffd100] hover:bg-[#e6bc00] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ffd100] transition-colors duration-200 ${
            isGenerating ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isGenerating ? 'Generating...' : 'Generate Lesson Plan'}
        </button>
      </div>
    </form>
  );
}

export default LessonPlanForm;