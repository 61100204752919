import { logEvent } from 'firebase/analytics';

export const trackEvent = (analytics, eventName, eventParams = {}) => {
  // Firebase Analytics
  if (analytics) {
    logEvent(analytics, eventName, eventParams);
    console.log('Firebase Analytics event tracked:', eventName, eventParams);
  }

  // Google Tag Manager
  if (window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      ...eventParams
    });
    console.log('Google Tag Manager event tracked:', eventName, eventParams);
  }
};
