import React from 'react';

const AppLayout = ({ children }) => {
  return (
    <div className="h-screen bg-gray-100 flex flex-col">
      {/* Main content */}
      <div className="flex flex-col flex-grow">
        {/* Page content */}
        <main className="flex-grow p-4 sm:p-6 overflow-auto">
          <div className="max-w-7xl mx-auto">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default AppLayout;