import React from 'react';
import { X } from 'lucide-react';

const FeedbackPopup = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-[9999] flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-xl flex flex-col items-center max-w-[90%] w-[500px] relative">
        <button 
          onClick={onClose} 
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
        >
          <X size={24} />
        </button>
        <div className="w-20 h-20 bg-blue-100 rounded-full flex items-center justify-center mb-4">
          <span className="text-4xl">🎉</span>
        </div>
        <h2 className="text-2xl font-bold text-[#186eef] mb-4">Your 10th Lesson Plan!</h2>
        <p className="text-gray-600 text-center mb-6">
          Congratulations on creating 10 lesson plans! We'd love to hear your feedback to make our tool even better.
        </p>
        <div className="flex space-x-4">
          <a 
            href="https://forms.gle/9RttFiizwbkQG3jR9" 
            target="_blank" 
            rel="noopener noreferrer"
            className="px-6 py-2 bg-[#186eef] text-white rounded-md hover:bg-[#1258c2] transition-colors duration-200"
          >
            Give Feedback
          </a>
          <button 
            onClick={onClose}
            className="px-6 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors duration-200"
          >
            Maybe Later
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackPopup;