import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

function LessonPlanDisplay({ 
  lessonPlanRef, 
  followUpRef, 
  worksheetRef, 
  answerKeyRef,
  activeTab, 
  setActiveTab,
  lessonPlan, 
  followUp, 
  worksheet, 
  answerKey, 
  isFollowUpGenerated, 
  isWorksheetGenerated, 
  onCreateWorksheet,
  isGenerating
}) {
  const tabs = [
    { id: 'lessonPlan', label: 'Lesson Plan', icon: '📚', content: lessonPlan, ref: lessonPlanRef },
    { id: 'worksheet', label: 'Worksheet', icon: '📝', content: worksheet, ref: worksheetRef },
    { id: 'answerKey', label: 'Answer Key', icon: '🔑', content: answerKey, ref: answerKeyRef },
  ];

  // Only add the followUp tab if it has been generated
  if (isFollowUpGenerated) {
    tabs.splice(1, 0, { id: 'followUp', label: 'Follow-up Plan', icon: '🔄', content: followUp, ref: followUpRef });
  }

  const handleShare = (content, title) => {
    if (window.parent !== window) {
      // We're in an iframe, use postMessage to communicate with the parent
      window.parent.postMessage({
        type: 'SHARE_CONTENT',
        payload: { title, content }
      }, '*');
    } else if (navigator.share && !window.frameElement) {
      navigator.share({
        title: title,
        text: content,
      }).then(() => {
        console.log('Successfully shared');
      }).catch((error) => {
        console.error('Error sharing:', error);
        fallbackToClipboard(content);
      });
    } else {
      fallbackToClipboard(content);
    }
  };

  const fallbackToClipboard = (content) => {
    if (window.parent !== window) {
      // We're in an iframe, use postMessage for clipboard operations
      window.parent.postMessage({
        type: 'COPY_TO_CLIPBOARD',
        payload: { content }
      }, '*');
    } else {
      navigator.clipboard.writeText(content).then(() => {
        alert('Content copied to clipboard!');
      }).catch((err) => {
        console.error('Failed to copy: ', err);
      });
    }
  };

  return (
    <div className="mt-8">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="flex border-b border-gray-200">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`${
                activeTab === tab.id
                  ? 'bg-[#186eef] text-white'  // Changed from 'bg-indigo-500' to 'bg-[#186eef]'
                  : 'bg-white text-gray-600 hover:bg-indigo-50'
              } flex-1 text-center py-4 px-1 transition-colors duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#186eef] focus:ring-opacity-50 relative`}
            >
              <span className="text-xl mr-2">{tab.icon}</span>
              <span className="font-medium">{tab.label}</span>
              {activeTab === tab.id && (
                <motion.div
                  className="h-1 bg-white absolute bottom-0 left-0 right-0"
                  layoutId="underline"
                />
              )}
            </button>
          ))}
        </div>
        <div className="p-6 min-h-[300px]">
          <AnimatePresence mode="wait">
            {tabs.map((tab) => (
              activeTab === tab.id && (
                <motion.div
                  key={tab.id}
                  ref={tab.ref} // Attach the ref here
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className="prose max-w-none"
                >
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold text-gray-800">{tab.label}</h2>
                    {tab.content && (
                      <button
                        onClick={() => handleShare(tab.content, `${tab.label} - Share`)}
                        className="px-4 py-2 bg-[#186eef] text-white rounded-md hover:bg-[#1258c7] transition-colors duration-300 text-sm font-medium"
                      >
                        Share
                      </button>
                    )}
                  </div>
                  {tab.content ? (
                    <div 
                      className="text-gray-600"
                      dangerouslySetInnerHTML={{ __html: tab.content.replace(/\n/g, '<br>') }} 
                    />
                  ) : (
                    ['worksheet', 'answerKey'].includes(tab.id) && !isWorksheetGenerated ? (
                      <button
                        onClick={onCreateWorksheet}
                        className="px-4 py-2 bg-white text-gray-700 border border-gray-300 rounded-md hover:bg-gray-100 transition-colors duration-300 text-sm font-medium"
                      >
                        Generate {tab.id === 'worksheet' ? 'Worksheet' : 'Answer Key'}
                      </button>
                    ) : (
                      <p className="text-gray-500 italic">No content available for this section yet.</p>
                    )
                  )}
                </motion.div>
              )
            ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

export default LessonPlanDisplay;