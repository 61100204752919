import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics, isSupported } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCPFkPnCICEhNSAZ1CToj3ZWb-C0w0-Xqc",
  authDomain: "lessonpgen.firebaseapp.com",
  projectId: "lessonpgen",
  storageBucket: "lessonpgen.appspot.com",
  messagingSenderId: "774899748846",
  appId: "1:774899748846:web:8ebaa1a9fdc85b252a88ff",
  measurementId: "G-Y1KWZ83DS2"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

// Initialize Analytics and export it
export const analytics = isSupported().then(yes => yes ? getAnalytics(app) : null);

// Export the app for potential use elsewhere
export default app;
