import React from 'react';

function LoadingPopup() {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-[9999] flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center max-w-[90%] w-[400px]">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-indigo-500 mb-4"></div>
        <p className="text-indigo-600 font-medium text-lg mb-2">Generating...</p>
        <p className="text-gray-500 text-sm text-center">Reminder: AI can make mistakes! Always double-check lesson plans for content and accuracy.</p>
      </div>
    </div>
  );
}

export default LoadingPopup;